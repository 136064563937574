import React, { useState } from 'react';
/* eslint-disable */
export const ThemeContext = React.createContext();

const Provider = ({children }) => {
  const [isDark, setTheme] = useState(true);
  
  return (
    <ThemeContext.Provider value={{
      isDark, changeTheme: () => setTheme(!isDark)
    }}>{children}</ThemeContext.Provider>
  )
}

export default ({ element }) => (
  <Provider>{element}</Provider>
)